import React from 'react';

import PoweredByReachality from '../../components/PoweredByReachality/PoweredByReachality';
import ChatHeader from '../../components/ChatHeader/ChatHeader';
import MessageEntry from '../../components/MessageEntry/MessageEntry';
import MessageList from '../../components/MessageList/MessageList';

import './ChatBot.scss';

function ChatBot() {

  return (
    <lumina-bot>
      <ChatHeader />
      <MessageList />
      <MessageEntry />
      <PoweredByReachality />
    </lumina-bot>
  );
}

export default ChatBot;